<template>
  <div class="footer">
    <div class="footer_container">
      <!-- <div class='show_msg'>
        <div class="msg_content">
          <span class="msg_label"> <i class="el-icon-s-cooperation"></i> open</span>
          <span class="msg_cunt">13254396</span>
        </div>
        <div class="msg_content">
          <span class="msg_label"> <i class="el-icon-s-check"></i> user</span>
          <span class="msg_cunt">125436</span>
        </div>
        <div class="msg_content">
          <span class="msg_label"> <i class="el-icon-s-data"></i> online</span>
          <span class="msg_cunt">1436</span>
        </div>
      </div> -->
      <div class="copyright">Copyright © 2019-2023 International milestone ltd.</div>
      <div class="copyright">All trades are automated via platform bots (with a 7-day trade cooldown limit for game trinkets).</div>
      <div class="bottom_tab">
        <router-link class="tab_link"
                     to="/serviceAgreement">Service Agreement</router-link>
        <router-link class="tab_link"
                     to='/privacypolicy'>Privacy Policy</router-link>
        <!-- <router-link class="tab_link" >Frequently Asked Questions </router-link> -->
        <router-link class="tab_link"
                     to='/aboutus'>About Us</router-link>
        <router-link class="tab_link"
                     to='/fair'>Probability Fairness</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
.footer {
  padding: 80px;
  margin-top: 50px;
  // background:#0b0c0d;
  .footer_container {
    width: 100%;
    .show_msg {
      max-width: 800px;
      margin: 60px auto;
      font-size: 14px;
      color: #ccc;
      display: flex;
      .msg_content {
        width: 33%;
        padding: 0 15px;
        border-left: 1px solid #eee;
        &:first-child {
          border: none;
        }
        > span {
          display: block;
        }
      }
    }
    .copyright {
      margin: 5px auto;
      font-size: 12px;
      color: #ccc;
    }
    .bottom_tab {
      font-size: 12px;
      color: #ccc;
      margin: 20px auto;
      .tab_link {
        padding: 0 5px;
        border-left: 1px solid #ccc;
        cursor: pointer;
        color: #fff;
        text-decoration-line: none;
        &:hover {
          text-decoration-line: underline;
        }
        &:first-child {
          border: none;
        }
      }
    }
  }
}
</style>