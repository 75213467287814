import Vue from 'vue' //引入Vue
import Router from 'vue-router' //引入vue-router
Vue.use(Router)


//Vue全局使用Router

export default new Router({
  // mode:"history",
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return {
      x: 0,
      y: 0
    }
  },

  routes: [ //配置路由，这里是个数组
    { //每一个链接都是一个对象
      path: '/', //链接路径
      redirect: '/index',
      component: () => import('@/view/index.vue'),
    },
    {
      path: '/index',
      name: 'index',
      component: () => import('@/view/index.vue'),
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('@/view/checkout.vue'),
    },
    {
      path: '/details',
      name: 'details',
      component: () => import('@/view/details.vue'),
    },
    {
      path: '/serviceAgreement',
      name: 'serviceAgreement',
      component: () => import('@/components/serviceAgreement.vue'),
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: () => import('@/components/privacypolicy.vue'),
    },
    {
      path: '/aboutus',
      name: 'aboutus',
      component: () => import('@/components/aboutus.vue'),
    },
    {
      path: '/fair',
      name: 'fair',
      component: () => import('@/components/fair.vue'),
    },
  ]
})