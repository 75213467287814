import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

Vue.prototype.$axios = axios
axios.defaults.baseURL = 'https://www.eqjncvb.com/v2.0/';
// axios.defaults.baseURL = 'https://www.salarypayway.com/v2.0/'
import store from './store/store'
import './style/global.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI)
import 'animate.css';
Vue.config.productionTip = false
import router from './router/index'
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')